/* eslint-disable import/prefer-default-export */
export const colors = {
  teal: '#60C6D6',
  violet: '#4F3D96',
  charcoal: '#3F4344',
  rust: '#F27219',
  lime: '#B5DD38',
  foam: '#AFCCE0',
  grape: '#666BAF',
}
